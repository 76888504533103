*{
    margin: 0;
    padding: 0;
}

/* Font */
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
body{
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    background-color: #1B1818;
   
}
/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.primaryColor{
color: #8CA9F5;
}
.secondaryColor{
color: #BAB9B9;
}
.mainColor{
color: #1B1818;
}
.bg-mainColor{
    background-color: #1B1818;
}
/* custom styles */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button{
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}

.custom-list-disc li::before {
    content: "";
    display: inline-block;
    width: 10px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: white; /* Adjust color as needed */
    transform: rotate(45deg);
    margin-right: 8px; /* Adjust spacing as needed */
  }
  .ticker-gredient {
    -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }
  @media (min-width: 1280px){
  .container{
    width: 90%;
  }}